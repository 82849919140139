.pacman__todo__list {
    display: grid;
    justify-content: start;
    align-items: center;
    border: 1px solid white;
    margin-bottom: 2rem !important;
}
.pacman__todo__list:hover {
    background-color: white;
    border: 0px solid white;
    box-shadow: 5px 5px 20px 2px rgba(0, 0, 0, 0.15);
  }

.pacman__todo__list {
    display: grid;
    justify-content: start;
    align-items: center;
    background: white;
    border: 0px solid white;
    margin-bottom: 2rem !important;
    cursor: pointer;
    box-shadow: 5px 5px 20px 2px rgba(0, 0, 0, 0.3);
}

.pacman__todo_copy {
    display: flex;
    justify-content: flex-end;
}
